<template>
  <div class="mobile-detail">
    <div class="content_container full-line" v-if="detail">
        <div style="font-size: 18px;margin: 20px 0;" v-html="detail.name"></div>
        <div style="display: flex;justify-content: space-between;color: #999;font-size: 12px;margin: 10px 0;padding: 10px;">
          <div v-html="detail.category_name"></div>
          <div class="span" v-html="formatDate(detail.add_time,'yyyy-MM-dd hh:mm')"></div>
        </div>
        <div style="font-size: 14px;line-height: 22px;text-align: left;padding: 10px;" v-html="detail.content"></div>
    </div>
  </div>
</template>

<script>
import { GetNews } from "@/api";
import { formatDate } from "@/utils/format.js";
import { loadDetailAction } from "@/utils/action.js";

export default {
  data: function () {
    return {
      id: "",
      detail: null,
    };
  },
  methods: {
    formatDate: formatDate,
    dataRequest: function () {
      loadDetailAction(this, GetNews, { id: this.id }, (data) => {
        this.detail = data.list[0];
      });
    },
  },
  mounted() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.dataRequest();
    }
  },
};
</script>